import React from "react";
import { withSubtheme } from '../../../../../StarberryComponentsMui';
import GlobalForm from "../../../forms/";
import { collectFormValues, validateFormFields } from "../../../forms/utils";
import { useFormState } from "../../../../../services";
import reportFaultFields from "../../../forms/Forms/newDocument";
import { trackNewDocument, getUserRole } from "../../../helper/eventTracking";
import  { getUser } from "../../../../../services/store/utils"

import defaults from "./dialogChildrenDefaults";

const DialogChildren = withSubtheme((props) => {
    const userData = getUser();
    const { className, persona, personaConfig } = props
    const property = props?.property || {}
    const property_detail = props?.property.property_detail || {}

    let associated_id =  property_detail.crm_id, associated_type = 'property';
    // if (property.owner_type === 'lettings' && property.current_tenancy_id) { // propertyid / tenancy id
    //     associated_id = property.current_tenancy_id;
    //     associated_type = 'tenancy';
    //}/* else if (property.is_owner) { // propertyid / tenancy id
    //     associated_id = property_detail.crm_id;
    //     associated_type = 'property';
    // }*/

    const assoicateType = personaConfig.associatedType; // come from theme

    if (property?.is_owner && (/*property_detail.vendor_id || */ property_detail.landlord_id)) {

        switch (assoicateType) {
            case 'property':
                associated_id =  property_detail.crm_id;
                associated_type = assoicateType
                break;
            // case 'vendor':
            //     associated_id =  property_detail.vendor_id;
            //     associated_type = 'assoicateType'
            //     break;
            case 'landlord':
                associated_id =  property_detail.landlord_id;
                associated_type = assoicateType
                break;
            default:
                break;
        }
    } else if (property?.is_owner === false) {
        switch (assoicateType) {
            case 'tenancy':
                if (property.current_tenancy_id) {
                    associated_id =  property.current_tenancy_id;
                    associated_type = assoicateType
                }
                break;
            case 'property':
                associated_id =  property_detail.crm_id;
                associated_type = 'property'
                break;
            case 'contact':
                if (userData.contact_id) {
                    associated_id =  userData.contact_id;
                    associated_type = assoicateType
                }
                break;
            // case 'applicant':
            // case 'tenant':
            //     associated_id =  userData.contact_id;
            //     associated_type = assoicateType
            //     break;
            default:
                break;
        }
    }

    // const associatedtype - property / tenancy

    const { state, services } = useFormState()
    let customFields = [...reportFaultFields];

    const [formFields, setFormFields ] = React.useState([...customFields]);

    const ref = React.useRef(null)
    const formStateType = {}
    const messageDialog = { message:state.message, severity:state.error ? 'error' : 'success'}
    const hideForm = (state.success && !state.error)
    const formData = { submitLabel: "Submit" }

    const onSubmit = async(event) => {
        event.preventDefault()

        const formWithValue = validateFormFields(ref, customFields)
        setFormFields(formWithValue);
        // inputRef
        if (ref.current.checkValidity() !== false) {
            const params = {
                form_name: "new_document",
                property_id: property_detail.crm_id,
                postcode: property_detail?.address?.postcode,
                search_type: property_detail?.search_type,
                is_owner: property.is_owner,
                associatedid: associated_id,
                associatedtype: associated_type,
                successMessage: "Document Posted Successfully",
                multipart: true,
                trackData: () => {
                    trackNewDocument({
                        eventLabel: `Property - ${property_detail?.crm_id}`,
                        eventRole: getUserRole(property_detail)
                    })
                }
            }
            const values = collectFormValues(ref, params)
            services.postForm(values)
        } else {
            // @todo
        }
    }

    return(
        <div className={className}>
            <GlobalForm
                formFields={formFields}
                handleSubmit={onSubmit}
                formStateType={formStateType}
                messageDialog={messageDialog}
                data={formData}
                ref={ref}
                hideForm={hideForm}
                persona={persona}
            />
        </div>
    )
}, 'dialogChildren', defaults)

export default DialogChildren
